import React, { useEffect, useState } from "react";
import _ from "lodash";

export default function SubTitle({ containerRef = undefined as any, refs = undefined as any, loaded = false as any }) {
	const [breakPoints, setBreakPoints] = useState<any>([]);

	// for containerRef
	useEffect(() => {
		containerRef?.current && setBreakPoints(_.compact([...containerRef?.current?.children].filter(child => child?.nodeName === "H2")));
	}, [loaded, containerRef]);

	// when array of refs is given
	useEffect(() => {
		refs?.current && setBreakPoints([...refs?.current]);
	}, [loaded, refs]);

	return (
		<section className="sections">
			{breakPoints?.map((breakPnt = undefined as any, key = 0 as number) => (
				<button
					key={key}
					onClick={() => {
						window.scrollTo({ top: breakPnt?.offsetTop - 15 });
					}}>
					{breakPnt?.innerText?.toLowerCase()}
				</button>
			))}
		</section>
	);
}
