import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

import { pages } from "./../../const";

import "./style.sass";

function Nav() {
	const [active, setActive] = useState(false);

	const location = useLocation();

	return (
		<nav
			className={cx({ active: active })}
			onClick={() => {
				setActive(!active);
			}}>
			<Link
				id="nav-logo"
				className="icon-logo"
				onClick={() => {
					setActive(false);
				}}
				to={"/"}>
				T. J. Sokol Libuš
			</Link>
			<button
				id="nav-open"
				className="icon"></button>
			<Link
				to="/dokumenty#ukrajina"
				id="nav-ukraine"
				className="icon"></Link>
			<section id="nav-links">
				{pages
					.filter(page => page.list === "nav")
					.map((page, index) => (
						<Link
							key={index}
							className={cx({ active: location.pathname === page.link })}
							onClick={() => {
								setActive(false);
							}}
							to={page.link}>
							{page.title}
						</Link>
					))}
			</section>
		</nav>
	);
}

export default Nav;
