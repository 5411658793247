import React from "react";

import AboutUs from "../../components/AboutUs";
import News from "../../components/News";
import Events from "../../components/Events";
import Schedule from "../../components/Schedule";

function Home() {
  return (
    <>
      <AboutUs cover={true} />
      <News />
      <Events />
      <Schedule />
    </>
  );
}

export default Home;
