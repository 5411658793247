import axios from "axios";

export function requestFetchPageAll() {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/page.php?All`,
	});
}

export const requestFetchPage = (target = "" as string) => {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/page.php?source=${target}`,
	});
};
