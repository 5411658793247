import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import cx from "classnames";

import Loader from "../../components/Loader";
import DocumentsList from "../DocumentsList";

import "./style.sass";

import { months } from "../../const";
import { fetchData } from "./../../redux";

function AboutUs({
  news = [] as any[],
  documents = undefined as any,
  fetchData = undefined as any,
}) {
  // if data were not loaded in App, load them separately
  useEffect(() => {
    if (!news.length) fetchData("news");
    if (!Object.keys(documents).length) fetchData("document");
  }, [fetchData, news, documents]);

  // last month to show news
  const [limit, setLimit] = useState<any>(
    moment().startOf("month").subtract(1, "month")
  );
  const [limitCnt, setLimitCnt] = useState<number>(0);

  return (
    <div>
      <h2>Aktuality</h2>
      <Loader loaded={news.length !== 0} id="news">
        {news
          .filter(({ date, pin }) => moment(date).isAfter(limit) || +pin)
          .map(({ id, pin, date, title, content }, key = 0 as number) => (
            <div key={key} className={cx({ pin: +pin })}>
              {(!+pin && <span>{moment(date).format("DD. MM. YYYY")}</span>) ||
                null}
              <h3>{title}</h3>
              {content && content !== "<p><br></p>" && <p>{parse(content)}</p>}
              <DocumentsList
                documents={documents?.news?.filter(
                  (document = undefined as any) =>
                    document.id_application === id
                )}
                hideEmpty
                hideMessage={false}
              />
            </div>
          ))}
        {(news.filter(
          (article) => moment(article?.date).isBefore(limit) && !+article?.pin
        ).length && (
          <button
            onClick={() => {
              limitCnt > 5
                ? setLimit(moment("1970-01-01"))
                : setLimit(limit.subtract(1, "month"));
              setLimitCnt(limitCnt + 1);
            }}
          >
            {limitCnt <= 5
              ? `zobrazit měsíc ${
                  months[moment(limit).subtract(1, "month").month()]
                }`
              : "zobrazit vše"}
          </button>
        )) ||
          null}
      </Loader>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    news: state.data.news,
    documents: state.data.document,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (target = "" as string) => dispatch(fetchData(target)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
