import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import "./style.sass";

import Files from "../../components/Files";
import Form from "../../components/Form";
import Input from "../../components/Input";

import NewAktuality from "./components/New";

import { fetchData } from "./../../../../redux";

function Aktuality({ news = [] as any[], fetchData = undefined as any }) {
	const [refresh, setRefresh] = useState(true);

	const [stop, setStop] = useState<number>(10);

	// needed "delay" to refresh map render
	useEffect(() => {
		setRefresh(true);
	}, [refresh]);

	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (!news.length) fetchData("news");
		else setRefresh(false);
	}, [fetchData, news]);

	return (
		<main id="administration-aktuality">
			<h1>Aktuality</h1>
			<h2>přidat novou aktualitu</h2>
			<NewAktuality />
			<h2>upravit aktuality</h2>
			{refresh
				? news?.map((values, key = 0 as number) => (
						<React.Fragment key={key}>
							<div className={cx("administration-container", { pin: +values.pin })}>
								<Form
									defaultValues={values}
									actions={["update", "delete"]}
									target="news">
									<div className="form-title">
										<section>
											<span>nadpis</span>
											<Input name="title" />
										</section>
										<section className="administration-aktuality-checkBox">
											<span>upevnit</span>
											<Input
												attr={{ type: "checkbox" }}
												name="pin"
											/>
										</section>
										<section>
											<span>datum zveřejnění</span>
											<Input
												attr={{ type: "date", min: "2018-01-01" }}
												name="date"
											/>
										</section>
									</div>
									<section>
										<span>zpráva</span>
										<Input
											type="textarea"
											name="content"
										/>
									</section>
								</Form>
								<Files
									application="news"
									id_application={values.id}
									referer="aktuality"
								/>
							</div>
							{stop === key && (
								<button
									className="stop"
									onClick={() => setStop(prevState => prevState + 7)}>
									Zobrazit další
								</button>
							)}
						</React.Fragment>
				  ))
				: null}
		</main>
	);
}

const mapStateToProps = (state: any) => {
	return {
		news: state.data.news,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Aktuality);
