import React, { useEffect } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";

import Loader from "../../components/Loader";

import { fetchPage } from "./../../redux";

function AboutUs({
  aboutus = "" as string,
  fetchPage = undefined as any,
  cover = false as boolean,
}) {
  // if data were not loaded in App, load them separately
  useEffect(() => {
    if (aboutus === "") fetchPage("aboutus");
  }, [fetchPage, aboutus]);

  return (
    <Loader loaded={aboutus !== ""} cover={cover}>
      {parse(aboutus)}
    </Loader>
  );
}

const mapStateToProps = (state: any) => {
  return {
    aboutus: state.page.aboutus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchPage: (target = "" as string) => dispatch(fetchPage(target)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
