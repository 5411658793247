import Buttons from "../Buttons";

import "./style.sass";

function Pop({ images = [] as any[], active = undefined as any, setActive = null as any, setPop = null as any }) {
	return (
		<div className="gallery-pop">
			<img
				className="gallery-pop-close"
				src="/images/icons/white/close.png"
				alt="Zavřít"
				width={32}
				height={32}
				onClick={() => setPop(false)}
			/>
			<img
				className="gallery-pop-image"
				src={`${process.env.REACT_APP_HOST}/data/image/${images[active].source}`}
				alt={images[active].alt}
			/>
			<Buttons
				imagesLen={images.length}
				active={active}
				setActive={setActive}
			/>
		</div>
	);
}

export default Pop;
