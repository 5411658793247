import React from "react";

import Files from "../../components/Files";

import "./style.sass";

export default function Banner() {
	return (
		<main id="administration-banner">
			<h1>Banner</h1>
			<Files
				target="image"
				application="banner"
				referer="banner"
			/>
		</main>
	);
}
