import axios from "axios";

export function requestStatus() {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/login.php`,
		withCredentials: true,
	});
}

export function requestUpdatePage(target = "" as string, data = {} as object) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/post/page.php`,
		withCredentials: true,
		params: {
			target,
		},
		data,
	});
}

export function requestUpdateData(target = "" as string, data = {} as object) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/post/data.php`,
		withCredentials: true,
		params: {
			target,
		},
		data,
	});
}

export function requestDeleteData(target = "" as string, data = {} as object) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/post/delete.php`,
		withCredentials: true,
		params: {
			target,
		},
		data,
	});
}

export function requestSaveData(target = "" as string, data = {} as object) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/post/save.php`,
		withCredentials: true,
		params: {
			target,
		},
		data,
	});
}

export function requestMoveData(target = "" as string, data = {} as any, action = "moveUp" as string) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/post/move.php`,
		withCredentials: true,
		params: {
			target,
		},
		data: { ...data, action: action },
	});
}
