import {
	ADMINISTRATION_STATUS,
	ADMINISTRATION_LOGIN,
	ADMINISTRATION_UPDATE,
	ADMINISTRATION_DELETE,
	ADMINISTRATION_SAVE,
	ADMINISTRATION_MOVE,
	ADMINISTRATION_OFFSET,
} from "./administrationTypes";

export const statusAdministration = () => {
	return {
		type: ADMINISTRATION_STATUS,
	};
};

export const loginAdministration = ({ login = false as boolean }) => {
	return {
		type: ADMINISTRATION_LOGIN,
		login,
	};
};

export const updateAdministration = (target = "" as string, refresh = "data" as string, data = {} as object) => {
	return {
		type: ADMINISTRATION_UPDATE,
		target,
		refresh,
		data,
	};
};

export const deleteAdministration = (target = "" as string, data = {} as object) => {
	return {
		type: ADMINISTRATION_DELETE,
		target,
		data,
	};
};

export const saveAdministration = (target = "" as string, data = {} as object) => {
	return {
		type: ADMINISTRATION_SAVE,
		target,
		data,
	};
};

export const moveAdministration = (target = "" as string, data = {} as object, action = "moveUp" as string) => {
	return {
		type: ADMINISTRATION_MOVE,
		target,
		data,
		action,
	};
};

export const offsetAdministration = (offset = 0 as number) => {
	return {
		type: ADMINISTRATION_OFFSET,
		offset,
	};
};
