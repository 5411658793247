import React from "react";
import moment from "moment";

import Loader from "../Loader";

import "./style.sass";

function DocumentsList({
	documents = [] as any[],
	showDate = false as boolean,
	placeholder = true as boolean,
	hideEmpty = false as boolean,
	hideMessage = true as boolean,
}) {
	return (
		<Loader
			loaded={documents.length}
			className="documents"
			placeholder={placeholder}
			hideEmpty={hideEmpty}
			hideMessage={hideMessage ? "zatím nebyly přidány žádné dokumenty" : hideMessage}>
			{documents?.map((document: any, index: number) => (
				<p
					key={index}
					className={`icon-logo documents-${document.type}`}
					style={{
						backgroundImage: `url(/images/icons/files/${document.type}.png)`,
					}}>
					{showDate && document.date && <span>{moment(document.date).format("DD. MM. YYYY")}</span>}
					<a
						href={`${process.env.REACT_APP_HOST}/data/document/${document.source}`}
						download>
						{document.title}
					</a>
				</p>
			))}
		</Loader>
	);
}

export default DocumentsList;
