import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import "./style.sass";

import Files from "../../components/Files";
import Form from "../../components/Form";
import Input from "../../components/Input";

import NewAkce from "./components/New";

import { fetchData } from "./../../../../redux";

import { months } from "../../../../const";

function Akce({ events = [] as any, fetchData = undefined as any }) {
	const [refresh, setRefresh] = useState(true);

	const [stop, setStop] = useState<number>(10);

	// needed "delay" to refresh map render
	useEffect(() => {
		setRefresh(true);
	}, [refresh]);

	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (!events.length) fetchData("event");
		else setRefresh(false);
	}, [fetchData, events]);

	const renderHeader = (previous = "1990-01-01" as string, current: string) => {
		const _previous = moment(previous);
		const _current = moment(current);

		return (
			<>
				{_previous.year() !== _current.year() && <h4 className="year">{_current.year()}</h4>}
				{_previous.month() !== _current.month() && <h5 className="month">{months[_current.month()]}</h5>}
			</>
		);
	};

	return (
		<main id="administration-akce">
			<h1>Akce</h1>
			<h2>přidat novou akci</h2>
			<span>datum je nutné zadat i s časem, jinak se akce neuloží</span>
			<NewAkce />
			<h2>upravit akce</h2>
			{refresh
				? events?.map((values: any, key: number, all: any) => (
						<React.Fragment key={key}>
							{renderHeader(all[key - 1]?.start_date, values.start_date)}
							<div className="administration-container">
								<Form
									defaultValues={values}
									actions={["update", "delete"]}
									target="event">
									<div className="form-title">
										<section>
											<span>nadpis</span>
											<Input name="title" />
										</section>
										<section>
											<span>datum začátku</span>
											<Input
												attr={{
													type: "datetime-local",
													min: "2018-01-01T00:00",
												}}
												name="start_date"
											/>
										</section>
										<section>
											<span>datum konce</span>
											<Input
												attr={{
													type: "datetime-local",
													min: "2018-01-01T00:00",
												}}
												name="end_date"
											/>
										</section>
									</div>
									<section>
										<span>místo konání</span>
										<Input name="place" />
									</section>
									<section>
										<span>zpráva</span>
										<Input
											type="textarea"
											name="content"
										/>
									</section>
								</Form>
								<Files
									application="events"
									id_application={values.id}
									referer="akce"
								/>
							</div>
							{stop === key && (
								<button
									className="stop"
									onClick={() => setStop(prevState => prevState + 7)}>
									Zobrazit další
								</button>
							)}
						</React.Fragment>
				  ))
				: null}
		</main>
	);
}

const mapStateToProps = (state: any) => {
	return {
		events: state.data.event,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Akce);
