import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { CookiesProvider } from "react-cookie";

import Home from "./pages/Home";
import ONas from "./pages/ONas";
import Oddily from "./pages/Oddily";
import Kontakt from "./pages/Kontakt";
import Dokumenty from "./pages/Dokumenty";
import Administrace from "./pages/Administrace";
import Pristupnost from "./pages/Pristupnost";

import { fetchDataAll, fetchPageAll } from "./redux";

import Nav from "./components/Nav";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import AdministrationNav from "./components/Administrace/components/Nav";

import { pages } from "./const";

function App({ fetchDataAll = undefined as any, fetchPageAll = undefined as any, login = false as boolean }) {
	useEffect(() => {
		fetchDataAll();
		fetchPageAll();
	}, [fetchDataAll, fetchPageAll]);

	// stores object of current page
	const [page, setPage] = useState<any>({
		title: "Hlavní stránka",
	});

	const location = useLocation();

	// makes title for current page
	useEffect(() => {
		setPage(
			pages.filter(page => page.link === location.pathname)[0] || {
				title: "Hlavní stránka",
				list: "nav",
			}
		);
		document.title = page.title + " | Sokol Libuš";
	}, [location, page.title]);

	return (
		<CookiesProvider>
			<Nav />
			{login && <AdministrationNav />}
			{page?.list === "nav" && <Banner />}
			<Routes>
				<Route
					index
					element={<Home />}
				/>
				<Route
					path="onas"
					element={<ONas />}
				/>
				<Route
					path="oddily"
					element={<Oddily />}
				/>
				<Route
					path="kontakt"
					element={<Kontakt />}
				/>
				<Route
					path="dokumenty"
					element={<Dokumenty />}
				/>
				<Route
					path="pristupnost"
					element={<Pristupnost />}
				/>
				<Route
					path="administrace/*"
					element={<Administrace />}
				/>
				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Routes>
			<Footer />
		</CookiesProvider>
	);
}

const mapStateToProps = (state: any) => {
	return {
		login: state.administration.login,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchDataAll: () => dispatch(fetchDataAll()),
		fetchPageAll: () => dispatch(fetchPageAll()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
