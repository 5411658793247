import axios from "axios";

export function requestFetchDataAll() {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/data.php?All`,
	});
}

export const requestFetchData = (target = "" as string, type = "" as string) => {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/data.php`,
		params: {
			[type]: target,
		},
	});
};

export function requestFetchDataSpec(target = "" as string) {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/get/component/${target}.php`,
	});
}
