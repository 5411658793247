import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./style.sass";

import Files from "../../components/Files";
import Form from "../../components/Form";
import Input from "../../components/Input";

import NewSquad from "./components/New";

import { fetchData } from "./../../../../redux";

function Oddily({ squads = [] as any[], fetchData = undefined as any }) {
	const [refresh, setRefresh] = useState(true);

	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (!squads.length) fetchData("squad");
		else setRefresh(false);
	}, [fetchData, squads]);

	// needed "delay" to refresh map render
	useEffect(() => {
		if (refresh === false) setRefresh(true);
	}, [refresh]);

	return (
		<main id="administration-oddily">
			<h1>Oddíly</h1>
			<section>
				<h2>Přidat nový oddíl</h2>
				<NewSquad />
			</section>
			<section>
				<h2>Upravit oddíly</h2>
				{refresh
					? squads?.map((values, key = 0 as number) => (
							<div
								className="administration-container"
								key={key}>
								<Form
									defaultValues={values}
									actions={[
										"update",
										"delete",
										...(+values.position !== 1 ? ["moveUp"] : []),
										...(+values.position !== squads.length - 1 ? ["moveDown"] : []),
									]}
									target="squad">
									<div className="form-title">
										<section>
											<span>název</span>
											<Input name="name" />
										</section>
									</div>
									<section>
										<span>informace</span>
										<Input
											type="textarea"
											name="content"
										/>
									</section>
									<div className="administration-oddily-timeAndContact">
										<section>
											<span>termín</span>
											<Input
												type="textarea"
												name="time"
											/>
										</section>
										<section>
											<span>kontakt</span>
											<Input
												type="textarea"
												name="contact"
											/>
										</section>
									</div>
								</Form>
								<Files
									application="squad"
									id_application={values.id}
									referer="oddily"
								/>
								<Files
									target="image"
									application="squad"
									id_application={values.id}
									referer="oddily"
								/>
							</div>
					  ))
					: null}
			</section>
		</main>
	);
}

const mapStateToProps = (state: any) => {
	return {
		squads: state.data.squad,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Oddily);
