import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import Login from "../../components/Administrace/pages/Login";
import Home from "../../components/Administrace/pages/Home";
import Banner from "../../components/Administrace/pages/Banner";
import Aktuality from "../../components/Administrace/pages/Aktuality";
import Akce from "../../components/Administrace/pages/Akce";
import Rozvrh from "../../components/Administrace/pages/Rozvrh";
import ONas from "../../components/Administrace/pages/ONas";
import Historie from "../../components/Administrace/pages/Historie";
import ZpravyZJednoty from "../../components/Administrace/pages/ZpravyZJednoty";
import Oddily from "../../components/Administrace/pages/Oddily";
import Kontakt from "../../components/Administrace/pages/Kontakt";
import Dokumenty from "../../components/Administrace/pages/Dokumenty";

import { statusAdministration } from "./../../redux";

function Administrace({ login = false as boolean, statusAdministration = undefined as any }) {
	useEffect(() => {
		// now ask if user is logged
		statusAdministration();
	}, [statusAdministration]);

	return login ? (
		<Routes>
			<Route
				index
				element={<Home />}
			/>
			<Route
				path="banner"
				element={<Banner />}
			/>
			<Route
				path="aktuality"
				element={<Aktuality />}
			/>
			<Route
				path="akce"
				element={<Akce />}
			/>
			<Route
				path="rozvrh"
				element={<Rozvrh />}
			/>
			<Route
				path="o-nas"
				element={<ONas />}
			/>
			<Route
				path="historie"
				element={<Historie />}
			/>
			<Route
				path="zpravy-z-jednoty"
				element={<ZpravyZJednoty />}
			/>
			<Route
				path="oddily"
				element={<Oddily />}
			/>
			<Route
				path="kontakt"
				element={<Kontakt />}
			/>
			<Route
				path="dokumenty"
				element={<Dokumenty />}
			/>
			<Route
				path="*"
				element={<Navigate to="/administrace" />}
			/>
		</Routes>
	) : (
		<Login />
	);
}

const mapStateToProps = (state: any) => {
	return {
		login: state.administration.login,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		statusAdministration: () => dispatch(statusAdministration()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Administrace);
