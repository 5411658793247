import React from "react";

import Files from "../../components/Files";

export default function Dokumenty() {
	return (
		<main>
			<h1>Dokumenty</h1>
			<span>soubory nejdříve nahrajte, pak upravte jejich název nebo datum zveřejnění</span>
			<Files
				application="document"
				referer="dokumenty"
			/>
			<section>
				<h2>Ukrajina</h2>
				<Files
					application="ukraine"
					referer="dokumenty"
				/>
			</section>
			<section>
				<h2>Cvičební provoz</h2>
				<Files
					application="operation"
					referer="dokumenty"
				/>
			</section>
		</main>
	);
}
