import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Editor from "../../components/Editor";
import Submit from "../../components/Submit";

import { fetchPage } from "./../../../../redux";

function ONas({ aboutus = "" as string, fetchPage = undefined as any, wrap = true }) {
	const [value, setValue] = useState("");

	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (aboutus === "") fetchPage("aboutus");
		else setValue(aboutus);
	}, [aboutus, fetchPage]);

	function renderContent(wrap: boolean) {
		return (
			<React.Fragment>
				{wrap ? <h1>O Nás</h1> : <h2>O Nás</h2>}
				<Editor
					{...{ value, setValue }}
					title
				/>
				<Submit
					target="aboutus"
					refresh="page"
					data={{ content: value }}
				/>
			</React.Fragment>
		);
	}

	if (wrap) return <main className="form">{renderContent(wrap)}</main>;
	return renderContent(wrap);
}

const mapStateToProps = (state: any) => {
	return {
		aboutus: state.page.aboutus,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchPage: (target = "" as string) => dispatch(fetchPage(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ONas);
