import moment from "moment";

export const midnight = (date = "" as string): boolean => {
	if (moment(date).isSame(moment(date).startOf("date"))) return true;
	return false;
};

// check interval to display days with event or to set event right
export const checkInterval = (start_date = "" as string, end_date = "" as string, date = "" as string): boolean => {
	// some events may dont have end_date
	if (!end_date) end_date = start_date;

	// basic check of interval
	if (
		moment(start_date).startOf("day").isBefore(moment(date).endOf("day")) &&
		moment(end_date).endOf("day").isAfter(moment(date).startOf("day"))
	) {
		// there has to be check of the end_date
		// if is midnight, then the last day cant be added to the hole interval
		// but only if it is not also the start day, it has to contain always at least one day
		if (moment(end_date).isSame(moment(date).startOf("minute"), "minute") && !moment(end_date).isSame(moment(start_date), "day"))
			return false;
		return true;
	}

	return false;
};

// returns end date for each event in right format
export const renderEndDate = (start_date = "" as string, end_date = "" as string): any => {
	if (end_date && moment(start_date).isSame(moment(end_date), "day"))
		// end date is same date as start date but has hour and minute also set
		return ` - ${moment(end_date).format("H:mm")}`;

	if (midnight(end_date)) {
		// the next days midnight was selected
		if (moment(start_date).isSame(moment(end_date).subtract(1, "day"), "day")) return null;

		// end date is not same date as start date and ends on midnight, one day has to be substracted
		return ` - ${moment(end_date).subtract(1, "day").format("dddd D. MMMM")}`;
	}

	return ` - ${moment(end_date).format("dddd D. MMMM H:mm")}`;
};

// only to display upcoming events
export const getEvents = (year = 2022 as number, month = 0 as number, events = [] as any[]) => {
	// first and last day of the month
	const firstDate = moment(`${year}-${("0" + (month + 1)).slice(-2)}-01`);
	const lastDate = moment(`${year}-${("0" + (month + 1)).slice(-2)}-01`).endOf("month");

	// offset days, that still get rendered in current month
	const start_date = firstDate.subtract(firstDate.day() - 1, "day").format("YYYY-MM-DD");
	const end_date = lastDate.add(7 - lastDate.day(), "day").format("YYYY-MM-DD");

	return events.filter(
		event =>
			checkInterval(start_date, end_date, event.start_date) ||
			checkInterval(start_date, end_date, event.end_date) ||
			(moment(start_date).isAfter(moment(event.start_date)) && moment(end_date).isBefore(moment(event.end_date)))
	);
};
