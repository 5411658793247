import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "./../../../../../../redux";

import Gallery from "../../../../../Gallery";
import Document from "./components/Document";
import Image from "./components/Image";

import "./style.sass";

function Edit({ target = "document" as string, id_application = 0 as number, data = undefined as any, fetchData = undefined as any }) {
	const [refresh, setRefresh] = useState(true);

	// if data were not loaded in App
	useEffect(() => {
		if (data === undefined) fetchData(target);
		else setRefresh(false);
	}, [target, data, fetchData, setRefresh]);

	// needed "delay" to refresh map render
	// must be after useEffect, which changes refresh
	useEffect(() => {
		setRefresh(true);
	}, [refresh]);

	// simplifies code below
	const getData = (data = [] as any[]): any[] => {
		return data?.filter((value: any) => (id_application ? value.id_application === id_application : true));
	};

	// return null, if there are no files to be  displayed
	if (!getData(data).length) return null;

	return (
		<>
			<h3>Upravit {target === "document" ? "dokumenty" : "obrázky"}</h3>
			<div className={`administration-data-edit administraction-data-edit-${target}`}>
				{refresh && target === "image" ? <Gallery images={getData(data)} /> : null}
				{refresh
					? getData(data)?.map((values: any, key = 0 as number) =>
							target === "document" ? (
								<Document
									key={key}
									{...{ target, values }}
									length={getData(data).length}
								/>
							) : (
								<Image
									key={key}
									{...{ target, values }}
									length={getData(data).length}
								/>
							)
					  )
					: null}
			</div>
		</>
	);
}

const mapStateToProps = (state: any, { target = "document" as string, application = "document" as string }) => {
	return {
		data: state.data[target][application],
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
