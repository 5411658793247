import React from "react";

import Form from "../../../../../Form";
import Input from "../../../../../Input";

export default function Document({ target = "document" as string, values = {} as any, length = 1 as number }) {
	return (
		<Form
			defaultValues={values}
			actions={[
				"update",
				"delete",
				...(+values.position !== length ? ["moveDown"] : []),
				...(+values.position !== 1 ? ["moveUp"] : []),
			]}
			target={target}>
			<a
				className="administration-data-icon icon"
				style={{
					backgroundImage: `url(/images/icons/files/${values.type}.png)`,
				}}
				href={`data/document/${values.source}`}
				download>
				{" "}
			</a>
			<section>
				<span>název</span>
				<Input name="title" />
			</section>
			<section>
				<span>datum zveřejnění</span>
				<Input
					attr={{ type: "date", min: "2018-01-01" }}
					name="date"
				/>
			</section>
		</Form>
	);
}
