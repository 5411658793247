import React from "react";
import cx from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import DocumentsList from "../DocumentsList";
import Sposors from "../Sposors";

import { pages } from "./../../const";

import "./style.sass";

function Footer({ documents = {} as any }) {
	let navigate = useNavigate();
	const location = useLocation();

	return (
		<footer>
			<Sposors />
			<dl>
				<dt>Stránky</dt>
				<dd>
					<button
						className={cx({ active: location.pathname === "/" })}
						onClick={() => {
							navigate("/");
							window.scrollTo({
								top: 0,
							});
						}}>
						Hlavní stránka
					</button>
					{pages
						.filter(page => page.list === "nav")
						.map((page, index) => (
							<button
								key={index}
								className={cx({ active: location.pathname === page.link })}
								onClick={() => {
									navigate(page.link);
									window.scrollTo({
										top: 0,
									});
								}}>
								{page.title}
							</button>
						))}
				</dd>
			</dl>
			<dl>
				<dt>Kontakt</dt>
				<dd>
					<div>
						<span>adresa</span>
						<a
							href="https://mapy.cz/s/mofukefelu"
							target="_blank"
							rel="noreferrer">
							Libušská 294/129, 142 00 Praha 4 - Libuš
						</a>
					</div>
					<div>
						<span>IČ</span>
						<p>613 87 258 </p>
					</div>
					<div>
						<span>telefon</span>
						<a href="tel:+420261912456">+420 261 912 456</a>
					</div>
					<div>
						<span>email</span>
						<a href="mailto:sokollibus@volny.cz">sokollibus@volny.cz</a>
					</div>
				</dd>
			</dl>
			<dl>
				<dt>Dokumenty</dt>
				<dd>
					<DocumentsList
						documents={documents?.document?.slice(0, 5)}
						placeholder={false}
					/>
				</dd>
			</dl>
			<dl>
				<dt>Ostatní</dt>
				<dd>
					<button
						className={cx({ active: location.pathname === "/administrace" })}
						onClick={() => {
							navigate("/administrace");
							window.scrollTo({
								top: 0,
							});
						}}>
						Administrace
					</button>
					<button
						className={cx({ active: location.pathname === "/pristupnost" })}
						onClick={() => {
							navigate("/pristupnost");
							window.scrollTo({
								top: 0,
							});
						}}>
						Přístupnost
					</button>
					<a
						href="http://old.sokollibus.cz/"
						target="_blank"
						rel="noreferrer">
						Starý web jednoty
					</a>
					<a
						href="https://www.linkedin.com/in/martin-fried-1213051a8/"
						target="_blank"
						rel="noreferrer">
						Autor webu
					</a>
				</dd>
			</dl>
		</footer>
	);
}

const mapStateToProps = (state: any) => {
	return {
		documents: state.data.document,
	};
};

export default connect(mapStateToProps, null)(Footer);
