interface Section {
	id: number | string;
	title: string;
}

interface SectionsProps {
	sections: Section[];
}

export default function Sections({ sections }: SectionsProps) {
	return (
		<section className="sections">
			{sections?.map(({ id = -1, title }, key = 0 as number) => (
				<a
					href={`#${id}`}
					key={key}>
					{title}
				</a>
			))}
		</section>
	);
}
