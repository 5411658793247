import { useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import parse from "html-react-parser";

import Loader from "../../components/Loader";
import Gallery from "../../components/Gallery";
import DocumentsList from "../../components/DocumentsList";
import Sections from "../../components/Sections";

import { fetchData } from "./../../redux";

import "./style.sass";

function Oddily({ squads = [] as any[], documents = {} as any, images = {} as any, fetchData = undefined as any }) {
	// if squads were not loaded in App
	useEffect(() => {
		if (!squads.length) fetchData("squad");
		if (!Object.keys(images).length) fetchData("image");
	}, [fetchData, squads.length, images]);

	return (
		<div id="squads">
			<h1>
				Oddíly
				<Sections
					sections={squads.reduce((acc, curr) => {
						return [...acc, { id: curr.id, title: curr.name }];
					}, [])}
				/>
			</h1>
			<Loader
				loaded={squads.length}
				cover={false}>
				{squads.map((squad, index) => (
					<dl
						key={index}
						id={squad.id}>
						<dt>{squad.name}</dt>
						<dd className={cx("squads-gallery", { active: squad.images?.length })}>
							<Gallery images={images?.squad?.filter((album: any) => album.id_application === squad.id)} />
						</dd>
						{squad.content && squad.content !== "<p><br></p>" && <dd className="squads-content">{parse(squad.content)}</dd>}
						{squad.time && squad.time !== "<p><br></p>" && (
							<dd className="squads-time">
								<span>termín</span>
								{parse(squad.time)}
							</dd>
						)}
						{squad.contact && squad.contact !== "<p><br></p>" && (
							<dd className="squads-contact">
								<span>kontakt</span>
								{parse(squad.contact)}
							</dd>
						)}
						<DocumentsList
							documents={documents?.squad?.filter((document = undefined as any) => document.id_application === squad.id)}
							hideEmpty
							hideMessage={false}
						/>
					</dl>
				))}
			</Loader>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		squads: state.data.squad,
		documents: state.data.document,
		images: state.data.image,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Oddily);
