import React from "react";

import Form from "../../../../../Form";
import Input from "../../../../../Input";

export default function Image({ target = "document" as string, values = {} as any, length = 1 as number }) {
	return (
		<Form
			defaultValues={values}
			actions={[
				"update",
				"delete",
				...(+values.position !== length ? ["moveDown"] : []),
				...(+values.position !== 1 ? ["moveUp"] : []),
			]}
			target={target}>
			<section>
				<span>název</span>
				<span className="administraction-span">{values.source}</span>
			</section>
			<section>
				<span>popis obrázku (pro slepé)</span>
				<Input
					attr={{ type: "text" }}
					name="alt"
				/>
			</section>
			<section>
				<span>odkaz</span>
				<Input
					attr={{ type: "text" }}
					name="url"
				/>
			</section>
		</Form>
	);
}
