import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./style.sass";

export default function Editor({
  value = "" as string,
  setValue = undefined as any,
  title = false as boolean,
}) {
  return (
    <div className="editor">
      {title && (
        <span className="editor-title">
          pro nadpis bloku používejte Heading 2, Heading 1 raději nepoužívejte
          vůbec
        </span>
      )}
      <ReactQuill theme="snow" value={value} onChange={setValue} />
    </div>
  );
}
