import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Editor from "../../components/Editor";
import Submit from "../../components/Submit";

import { fetchPage } from "./../../../../redux";

function Kontakt({ contact = "" as string, fetchPage = undefined as any }) {
	const [value, setValue] = useState("");

	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (contact === "") fetchPage("contact");
		else setValue(contact);
	}, [contact, fetchPage]);

	return (
		<main className="form">
			<h1>Kontakt</h1>
			<Editor
				{...{ value, setValue }}
				title
			/>
			<Submit
				target="contact"
				refresh="page"
				data={{ content: value }}
			/>
		</main>
	);
}

const mapStateToProps = (state: any) => {
	return {
		contact: state.page.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchPage: (target = "" as string) => dispatch(fetchPage(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Kontakt);
