import React, { useState } from "react";
import moment from "moment";

import Editor from "../Editor";

import { FormContext } from "../Form";

export default function Input({ type = "input" as string, attr = { type: "text" } as any, name = "" as string }) {
	const valuesContext: any = React.useContext(FormContext);

	// this is important, because input checked attribute wont get refreshed on valuesContext change
	// event if in parent component (Form) state is different
	const [checked, setChecked] = useState(attr.type === "checkbox" ? +valuesContext[name] === 1 : undefined);
	const toggleChecked = (newState: boolean) => {
		// like this pin values gets refreshed
		setChecked(newState);
		// this is to make code below simplier to read
		valuesContext[name] = newState ? 1 : 0;
	};

	const renderValue = () => {
		switch (attr.type) {
			case "date":
				return moment(valuesContext[name]).format("YYYY-MM-DD");
			case "datetime-local":
				return moment(valuesContext[name]).format("YYYY-MM-DDTHH:mm");
			case "checkbox":
				return checked;
			default:
				return valuesContext[name];
		}
	};

	if (type === "input")
		return (
			<input
				{...attr}
				checked={checked}
				defaultValue={renderValue()}
				onChange={event => {
					attr.type === "checkbox" ? toggleChecked(!checked) : (valuesContext[name] = event?.target?.value);
				}}
			/>
		);

	return (
		<Editor
			value={valuesContext[name]}
			setValue={(value = "" as string) => {
				valuesContext[name] = value;
			}}
		/>
	);
}
