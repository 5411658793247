import "./style.sass";

function Login() {
	return (
		<main id="administration-login">
			<h1>Administrace</h1>
			<form
				action={`${process.env.REACT_APP_HOST}/api/login.php`}
				method="post">
				<input
					type="password"
					placeholder="heslo"
					name="password"
				/>
				<input
					type="submit"
					className="administration-submit"
					value="přihlásit"
				/>
			</form>
		</main>
	);
}

export default Login;
