import moment from "moment";
import { DATA_UPDATE, DATA_DELETE, DATA_SAVE, DATA_MOVE } from "./dataTypes";

const initialState = {
	document: {},
	image: {},
	event: [],
	squad: [],
	news: [],
	schedule: {},
};

const sortValues = (values = [] as any[]) => {
	return values.sort((a, b) => {
		if ((!+a.pin && +b.pin) || (+a.pin && !+b.pin)) return +a.pin ? -1 : 1;

		const cmpA = +a.position || -moment(a.date || a.start_date).valueOf();
		const cmpB = +b.position || -moment(b.date || b.start_date).valueOf();
		return cmpA > cmpB ? 1 : cmpA < cmpB ? -1 : 0;
	});
};

function documentReducer(state = initialState as any, action: any) {
	const {
		document = state.document,
		image = state.image,
		squad = state.squad,
		event = state.event,
		schedule = state.schedule,
		news = state.news,
	} = action?.data || state;

	const { application, id_application } = action?.data || {
		application: undefined,
		id_application: 0,
	};

	let stateRest = [] as any[];
	switch (action.type) {
		case DATA_UPDATE:
			// only those are in array, therefore can be filtered
			// rest without the changed one
			stateRest = state[action.target]?.filter((row: any) => row.id !== action.value.id);

			return {
				...state,
				// important to keep same order
				[action.target]: sortValues([action.value, ...stateRest]),
			};
		case DATA_DELETE:
			const { id } = action.data;
			let stateSort = state[action.target] as any[];

			if (["event", "news"].includes(action.target)) {
				return {
					...state,
					[action.target]: sortValues(stateSort?.filter(row => row.id !== id)),
				};
			}

			// if id_application is provided, selects rest of state, where id_application does not match
			// this part of state does not have to be resorted
			if (id_application) stateRest = state[action.target][application]?.filter((row: any) => row.id_application !== id_application);

			// selects part of state, where row has to be removed and sorts it
			if (application) stateSort = state[action.target][application];

			// option for id_application has to be event here, so that stateSort can be used between 3 scenarios - id_app, app, not even app
			stateSort = stateSort
				?.filter((row: any) => (id_application ? row.id_application === id_application : true) && row.id !== id)
				?.map((row: any, key: number) => (row.position = key + 1) && row);

			return {
				...state,
				// if application is provided, other part of state has to be returned
				// for this option stateRest is empty
				[action.target]: application
					? {
							...state[action.target],
							[application]: [...stateRest, ...stateSort],
					  }
					: stateSort,
			};
		case DATA_SAVE:
			if (["event", "news"].includes(action.target)) {
				return {
					...state,
					[action.target]: sortValues([action.value[0], ...state[action.target]]),
				};
			}

			// for squads, where position value has to be resorted
			const stateMoved = state[action.target].map((row: any) => (row.position = +row.position + 1) && row);
			return {
				...state,
				[action.target]: sortValues([action.value[0], ...stateMoved]),
			};
		case DATA_MOVE:
			const { position } = action.data;
			const newPosition = action.action === "moveUp" ? +position - 1 : +position + 1;

			let stateSwapped = state[action.target] as any[];
			if (application) stateSwapped = state[action.target][application];

			// if id_application is provided, the option has to be there
			stateSwapped = stateSwapped?.map((row: any) => {
				if ((id_application ? +row.id_application === +id_application : true) && +row.position === newPosition)
					row.position = position;
				else if ((id_application ? +row.id_application === +id_application : true) && +row.position === +position)
					row.position = newPosition;
				return row;
			});

			return {
				...state,
				// same trick as in delete action
				[action.target]: application
					? {
							...state[action.target],
							[application]: sortValues(stateSwapped),
					  }
					: sortValues(stateSwapped),
			};
		default:
			return {
				...state,
				document,
				image,
				squad,
				event,
				schedule,
				news,
			};
	}
}

export default documentReducer;
