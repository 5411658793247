import { call, put } from "redux-saga/effects";

import {
	requestStatus,
	requestSaveData,
	requestDeleteData,
	requestMoveData,
	requestUpdateData,
	requestUpdatePage,
} from "../requests/administration";

import { loginAdministration, setPage, saveData, deleteData, moveData, updateData } from "./../../redux";

export function* handleRequestStatus(): any {
	try {
		const response = yield call(requestStatus);
		yield put(loginAdministration({ login: response.data }));
	} catch (error) {
		console.log(error);
	}
}

export function* handleRequestUpdate(args = undefined as any): any {
	const { target, refresh, data } = args;

	if (refresh === "page")
		try {
			yield call(requestUpdatePage, target, data);
			yield put(setPage({ [target]: data.content }));
		} catch (error) {
			console.log(error);
		}
	else if (refresh === "data")
		try {
			// important to clear empty elements, so BE does not get confused
			let cleanData = {};
			Object.keys(data).forEach(key => {
				if (data[key] || ["alt", "url", "pin", "end_date"].includes(key)) cleanData = { ...cleanData, [key]: data[key] };
			});

			yield call(requestUpdateData, target, cleanData);
			yield put(updateData(target, cleanData));
		} catch (error) {
			console.log(error);
		}
}

export function* handleRequestDelete(args = undefined as any): any {
	const { target, data } = args;

	try {
		// important to clear empty elements, so BE does not get confused
		let cleanData = {};
		Object.keys(data).forEach(val => {
			const newVal = data[val];
			cleanData = newVal ? { ...cleanData, [val]: newVal } : cleanData;
		});

		yield call(requestDeleteData, target, cleanData);
		yield put(deleteData(target, cleanData));
	} catch (error) {
		console.log(error);
	}
}

export function* handleRequestSave(args = undefined as any): any {
	const { target, data } = args;

	try {
		// important to clear empty elements, so BE does not get confused
		let cleanData = {};
		Object.keys(data).forEach(key => {
			const newVal = data[key];
			cleanData = newVal ? { ...cleanData, [key]: newVal } : cleanData;
		});

		const response = yield call(requestSaveData, target, data);
		yield put(saveData(target, response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* handleRequestMove(args = undefined as any): any {
	const { target, data, action } = args;

	try {
		yield call(requestMoveData, target, data, action);
		yield put(moveData(target, data, action));
	} catch (error) {
		console.log(error);
	}
}
