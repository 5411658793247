import "./style.sass";

import video from "./video/200.gif";

function Loader(props = undefined as any) {
	const { loaded, placeholder = true, className, id, children, cover = true, hideEmpty = false, hideMessage = undefined } = props;

	// basic return
	if (loaded) {
		if (cover) return <div {...{ className, id }}>{children}</div>;
		return <>{children}</>;
	}

	// for DocumentsList mostly
	if (hideEmpty) return hideMessage || null;

	// waiting animation
	return (
		<div
			className="loader"
			id={id}>
			<span>čekáme na data...</span>
			{placeholder && (
				<img
					src={video}
					alt="načítání..."
				/>
			)}
		</div>
	);
}

export default Loader;
