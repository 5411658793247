import React, { useRef } from "react";

import SubTitle from "../../components/SubTitle";

function Pristupnost() {
  const refs = useRef<any>([]);

  return (
    <div>
      <h1>
        Prohlášení o přístupnosti webu T. J. Sokol Libuš
        <SubTitle refs={refs} />
      </h1>
      <h2
        ref={(el) => {
          refs.current[0] = el;
        }}
      >
        POVINNÉ POŽADAVKY NA OBSAH
      </h2>
      <p>
        Provozovatel tohoto webu se zavazuje ke zpřístupnění obsahu této webové
        prezentace v souladu se směrnicí Evropského parlamentu a Rady (EU)
        2016/2102, dále v souladu se zákonem č. 99/2019 Sb., o přístupnosti
        internetových stránek a mobilních aplikací a o změně zákona č. 365/2000
        Sb., o informačních systémech veřejné správy a o změně některých dalších
        zákonů ve znění pozdějších předpisů. Toto prohlášení o přístupnosti se
        vztahuje na tuto doménu - sokollibus.cz
      </p>
      <h2
        ref={(el) => {
          refs.current[1] = el;
        }}
      >
        Stav souladu
      </h2>
      <p>
        Tyto internetové stránky jsou v souladu s výše uvedenými předpisy a dále
        s EN 301 549 V2 1.2 a standardem WCAG 2.1 s výjimkami uvedenými níže.
        <br />
        <br />
        Některé starší fotografie ve fotogaleriích postrádají alternativní
        textový popis. Některá videa mohou postrádat titulky. Uvedení těchto
        nedostatků do souladu se zákonem a s pravidly přístupnosti by v tomto
        případě bylo pro provozovatele finančně náročné, takže podle § 7 zákona
        99/2019 Sb. toto není dodatečně upravováno.
        <br />
        <br />
        Některé informace na tomto webu jsou nabízeny v podobě dokumentů formátu
        PDF. Pro zobrazení takových dokumentů je třeba mít nainstalovaný
        prohlížeč Adobe Reader, který je zdarma ke stažení z internetového
        serveru společnosti Adobe. Případně některý z alternativních prohlížečů
        (Foxit Reader, PDF-XChange Viewer a mnoho dalších). Většina dokumentů ve
        formátu PDF jde také přímo zobrazit v běžných prohlížečích. Dále zde
        mohou být dokumenty ve formátu MS Office, případně podobných
        kancelářských aplikací (ve formátech .doc, .docx, .odt, .xls, .xlsx,
        .ods a podobných). Takovéto soubory dokáže zobrazit a editovat většina
        běžně používaných textových editorů (MS Office, Libre Office, Open
        Office). V některých případech se mohou zobrazovat přímo v prohlížeči v
        přístupné formě.
        <br />
        <br />
        Toto prohlášení bylo vypracováno dne 5. 7. 2020
        <br />
        <br />
        Toto prohlášení bylo vypracováno posouzením Mgr. Jany Vlkové, členky
        výboru T. J. Sokol Libuš a upravovatelkou těchto internetových stránek.
        Pro vypracování prohlášení byly použity výše uvedené zákony a
        legislativní normy a metodický pokyn MVČR k tomuto zákonu, dále normy EN
        301 549 V2 1.2 a mezinárodně uznávané standardy Web Content
        Accessibility Guidelines – WCAG 2.1.
      </p>
      <h2
        ref={(el) => {
          refs.current[2] = el;
        }}
      >
        Zpětná vazba a kontaktní údaje
      </h2>
      <p>
        Kontakt na provozovatele: Vaše případné náměty či informace o problémech
        při zobrazovaní těchto stránek můžete zaslat na adresu:
        <a href="mailto: sokollibus@volny.cz"> sokollibus@volny.cz</a>
        <br />
        <br />
        Kontakt na dodavatele technického řešení:
        <a href="mailto: martin.fried@email.cz"> martin.fried@email.cz</a>
      </p>
      <h2
        ref={(el) => {
          refs.current[3] = el;
        }}
      >
        Postupy pro prosazování práva
      </h2>
      <p>
        V případě neuspokojivé odpovědi na oznámení nebo žádost zaslanou v
        souladu s čl. 7 odst. 1 písm. b) směrnice, kontaktujte příslušný orgán
        pro prosazování práva:
        <br />
        <br />
        Libušská 294/129, 142 00 Praha 4 - Libuš
        <br />
        Tel.: +420 261 912 456 nebo +420 607 668 669
        <br />
        e-mail: sokollibus@volny.cz
        <br />
      </p>
    </div>
  );
}

export default Pristupnost;
