import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";

import Loader from "../../components/Loader";
import SubTitle from "../../components/SubTitle";

import { fetchPage } from "./../../redux";

function Kontakt({ contact = "" as string, fetchPage = undefined as any }) {
	// if data were not loaded in App
	useEffect(() => {
		if (contact === "") fetchPage("contact");
	}, [fetchPage, contact]);

	const containerRef = useRef<any>(null);

	return (
		<div ref={containerRef}>
			<h1>
				Kontakt
				<SubTitle
					containerRef={containerRef}
					loaded={contact !== ""}
				/>
			</h1>
			<Loader
				loaded={contact !== ""}
				cover={false}>
				{parse(contact)}
			</Loader>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		contact: state.page.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchPage: (target = "" as string) => dispatch(fetchPage(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Kontakt);
