import React from "react";

import ONas from "../ONas";
import NewAktuality from "../Aktuality/components/New";
import NewAkce from "../Akce/components/New";
import Rozvrh from "../Rozvrh";

export default function Home() {
	return (
		<main>
			<h1>Administrace</h1>
			<ONas wrap={false} />
			<section id="administration-aktuality">
				<h2>přidat novou aktualitu</h2>
				<NewAktuality pure />
			</section>
			<section id="administration-akce">
				<h2>přidat novou akci</h2>
				<NewAkce pure />
			</section>
			<Rozvrh wrap={false} />
		</main>
	);
}
