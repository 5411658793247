import React from "react";

import "./style.sass";

function Buttons({
  imagesLen = 0 as number,
  active = undefined as any,
  setActive = null as any,
}) {
  return (
    <div className="gallery-buttons">
      {(active && (
        <button
          className="icon gallery-button gallery-button-left"
          onClick={() => {
            setActive(active - 1);
          }}
        ></button>
      )) ||
        null}
      {(active !== imagesLen - 1 && (
        <button
          className="icon gallery-button gallery-button-right"
          onClick={() => {
            setActive(active + 1);
          }}
        ></button>
      )) ||
        null}
    </div>
  );
}

export default Buttons;
