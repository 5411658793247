export const pages = [
  { link: "/onas", title: "O nás", list: "nav" },
  { link: "/oddily", title: "Oddíly", list: "nav" },
  { link: "/kontakt", title: "Kontakt", list: "nav" },
  { link: "/dokumenty", title: "Dokumenty", list: "nav" },
  { link: "/pristupnost", title: "Přístupnost" },
  {
    link: "/administrace",
    title: "Administrace",
    list: "admin",
  },
  {
    link: "/administrace/banner",
    title: "Banner",
    list: "admin",
  },
  {
    link: "/administrace/aktuality",
    title: "Aktuality",
    list: "admin",
  },
  { link: "/administrace/akce", title: "Akce", list: "admin" },
  { link: "/administrace/rozvrh", title: "Rozvrh", list: "admin" },
  {
    link: "/administrace/o-nas",
    title: "O Nás",
    list: "admin",
  },
  { link: "/administrace/historie", title: "Historie", list: "admin" },
  {
    link: "/administrace/zpravy-z-jednoty",
    title: "Zprávy z jednoty",
    list: "admin",
    type: "admin",
  },
  { link: "/administrace/oddily", title: "Oddíly", list: "admin" },
  {
    link: "/administrace/kontakt",
    title: "Kontakt",
    list: "admin",
  },
  {
    link: "/administrace/dokumenty",
    title: "Dokumenty",
    list: "admin",
  },
  {
    link: "/administrace/",
    title: "Administrace",
  },
];

export const months = [
  "leden",
  "únor",
  "březen",
  "duben",
  "květen",
  "červen",
  "červenec",
  "srpen",
  "září",
  "říjen",
  "listopad",
  "prosinec",
];
