import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import cx from "classnames";
import parse from "html-react-parser";
import _ from "lodash";

import Loader from "../Loader";
import Calendar from "./components/Calendar";
import DocumentsList from "../DocumentsList";

import { fetchData } from "./../../redux";

import { midnight, checkInterval, renderEndDate, getEvents } from "./helper";

import "./style.sass";

function Events({ events = {} as any, documents = {} as any, fetchData = undefined as any }) {
	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (!Object.keys(events).length) fetchData("event");
	}, [fetchData, events]);

	// variables for selecting current month
	const [year, setYear] = useState<number>(moment().year());
	const [month, setMonth] = useState<number>(moment().month());

	// current selected day
	const [active, setActive] = useState<string>("");

	const renderEventsMap = (events = [] as any[]) => {
		return events.map(({ id, title, content, start_date, end_date, place }, key = 0 as number) => (
			<dl
				key={key}
				className={cx({
					active: checkInterval(start_date, end_date, active),
					today: checkInterval(start_date, end_date, moment().format("YYYY-MM-DD")),
				})}>
				<dd className="events-time">
					{moment(start_date).format("dddd D. MMMM" + (midnight(start_date) ? "" : " H:mm"))}
					{end_date && renderEndDate(start_date, end_date)}
					{place && <b>{place}</b>}
				</dd>
				<dt>{title}</dt>
				{content && content !== "<p><br></p>" && <dd>{parse(content)}</dd>}
				<dd>
					<DocumentsList
						documents={documents?.events?.filter((document = undefined as any) => document.id_application === id)}
						hideEmpty
						hideMessage={false}
					/>
				</dd>
			</dl>
		));
	};

	const renderEvents = (events = [] as any[], active = "" as string) => {
		const activeEvents = events?.filter(({ start_date, end_date }) => checkInterval(start_date, end_date, active));
		const upcomingEvents = _.takeRight(
			events?.filter(({ start_date, end_date }) =>
				moment(end_date || start_date)
					.endOf("day")
					.isAfter(moment().startOf("day"))
			),
			5
		);

		if (active === "") {
			if (upcomingEvents.length) return renderEventsMap(upcomingEvents.reverse());

			// active day is not selected and there are not any upcoming events
			return <span>tento měsíc už nejsou žádné plánované akce</span>;
		} else if (activeEvents.length) return renderEventsMap(activeEvents);

		return <span>tento den není žádná plánovaná akce</span>;
	};

	return (
		<div id="events">
			<h2>Akce</h2>
			<Calendar
				{...{
					active,
					setActive,
					month,
					setMonth,
					year,
					setYear,
					checkInterval,
				}}
			/>
			<Loader
				loaded={Object.keys(events).length}
				id="events-list">
				{renderEvents(getEvents(year, month, events), active)}
			</Loader>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		events: state.data.event,
		documents: state.data.document,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
