import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";

import Loader from "../../components/Loader";
import SubTitle from "../../components/SubTitle";
import AboutUs from "../../components/AboutUs";
import DocumentsList from "../../components/DocumentsList";
import Sections from "../../components/Sections";

import { fetchPage, fetchData } from "./../../redux";

function ONas({
	aboutus = "" as string,
	history = "" as string,
	documents = {} as any,
	fetchPage = undefined as any,
	fetchData = undefined as any,
}) {
	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (history === "") fetchPage("history");
		if (!Object.keys(documents).length) fetchData("document");
	}, [history, fetchPage, documents, fetchData]);

	const containerRef = useRef<any>(null);

	return (
		<div id="aboutus">
			<h1>
				O nás
				<SubTitle
					containerRef={containerRef}
					loaded={aboutus !== "" || history !== ""}
				/>
				<Sections sections={[{ id: "zpravy-z-jednoty", title: "Zprávy z jednoty" }]} />
			</h1>
			<section ref={containerRef}>
				<AboutUs />
				<Loader
					loaded={history !== ""}
					cover={false}>
					{parse(history)}
				</Loader>
			</section>
			<section id="zpravy-z-jednoty">
				<h2>Zprávy z jednoty</h2>
				<DocumentsList documents={documents?.report} />
			</section>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		aboutus: state.page.aboutus,
		history: state.page.history,
		documents: state.data.document,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchPage: (target = "" as string) => dispatch(fetchPage(target)),
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ONas);
