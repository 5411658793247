import React from "react";

import Schedule from "../../../Schedule";
import New from "../../components/Files/components/New";

export default function Rozvrh({ wrap = true }) {
	function renderContent(wrap: boolean) {
		return (
			<React.Fragment>
				{wrap ? <h1>Rozvrh tělocvičny</h1> : <h2>Rozvrh tělocvičny</h2>}
				<section>
					{wrap ? <h2>Nahrát nový rozvrh</h2> : <h3>Nahrát nový rozvrh</h3>}
					<span>nový rozvrh musí být formátu .csv</span>
					<span>pro vytvoření hledejte možnost "uložit jako", nebo "exportovat"</span>
					<New url={`${process.env.REACT_APP_HOST}/api/post/component/schedule.php`} />
				</section>
				<section>
					{wrap ? <h2>Aktuální rozvrh</h2> : <h3>Aktuální rozvrh</h3>}
					<div id="schedule">
						<Schedule pure />
					</div>
				</section>
			</React.Fragment>
		);
	}

	if (wrap) return <main>{renderContent(wrap)}</main>;

	return renderContent(wrap);
}
