import React, { useEffect, useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import {
	saveAdministration,
	deleteAdministration,
	moveAdministration,
	updateAdministration,
	offsetAdministration,
} from "../.././.././../redux";

import "./style.sass";

export const FormContext = React.createContext({});

function Form({
	target = "news" as string,
	defaultValues = {} as any,
	actions = [] as any[],
	children = undefined as any,
	saveAdministration = undefined as any,
	deleteAdministration = undefined as any,
	moveAdministration = undefined as any,
	updateAdministration = undefined as any,
	offset = 0 as number,
	offsetAdministration = undefined as any,
}) {
	const [values, setValues] = useState<any>({ ...defaultValues });
	const [deleteToggle, setDeleteToggle] = useState(false);

	const [refresh, setRefresh] = useState(true);
	// needed "delay" to refresh map render
	useEffect(() => {
		setRefresh(true);
	}, [refresh]);

	useEffect(() => {
		// silly trick not to scroll randomly
		if (offset && Math.abs(window.pageYOffset - offset) > 2) {
			window.scrollTo(0, offset);
			offsetAdministration(0);
		}

		setTimeout(() => {
			const footer = document.getElementsByTagName("footer")[0] as HTMLElement;
			footer.style.removeProperty("top");
			document.body.style.removeProperty("overflow");
		}, 100);
	}, [offset, offsetAdministration]);

	const actionHandler = (target = "news" as string, action = "update" as string) => {
		switch (action) {
			case "save":
				saveAdministration(target, values);
				setValues({ ...defaultValues });

				// important to refresh after new element is given
				// only change of state does not refresh this form
				setRefresh(false);
				break;
			case "delete":
				setDeleteToggle(!deleteToggle);
				deleteToggle &&
					deleteAdministration(target, {
						id: values.id,
						application: values.application || undefined,
						id_application: values.id_application || undefined,
					});
				break;
			case "moveUp":
			case "moveDown":
				const data = {
					id: values.id,
					position: values.position,
					application: values.application || undefined,
					id_application: values.id_application || undefined,
				};
				moveAdministration(target, data, action);
				break;
			case "update":
			default:
				updateAdministration(target, "data", values);
				break;
		}
	};

	return refresh ? (
		<FormContext.Provider value={values}>
			<div className="form-container">
				<div className="form">{children}</div>
				<section>
					{actions.map((action, index) => (
						<button
							className={cx(`icon form-action-${action}`, {
								clicked: action === "delete" && deleteToggle,
							})}
							key={index}
							onClick={() => {
								const footer = document.getElementsByTagName("footer")[0] as HTMLElement;
								footer.style.top = footer.offsetTop + "px";
								document.body.style.overflow = "hidden";

								offsetAdministration(window.pageYOffset);
								actionHandler(target, action);
							}}></button>
					))}
				</section>
			</div>
		</FormContext.Provider>
	) : null;
}

const mapStateToProps = (state: any) => {
	return {
		offset: state.administration.offset,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateAdministration: (target = "" as string, refresh = "data" as string, values = {} as any) =>
			dispatch(updateAdministration(target, refresh, values)),
		deleteAdministration: (target = "" as string, data = {} as any) => dispatch(deleteAdministration(target, data)),
		saveAdministration: (target = "" as string, values = {} as any) => dispatch(saveAdministration(target, values)),
		moveAdministration: (target = "" as string, data = {} as any, action = "moveUp" as string) =>
			dispatch(moveAdministration(target, data, action)),
		offsetAdministration: (offset = 0 as number) => dispatch(offsetAdministration(offset)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
