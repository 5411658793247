import Form from "../../../../components/Form";
import Input from "../../../../components/Input";

import "./../../style.sass";

function NewAkce({ pure = false as boolean }) {
	const renderForm = () => (
		<Form
			actions={["save"]}
			target="event"
			defaultValues={{ title: "", content: "" }}>
			<div className="form-title">
				<section>
					<span>nadpis</span>
					<Input name="title" />
				</section>
				<section>
					<span>datum začátku</span>
					<Input
						attr={{ type: "datetime-local", min: "2018-01-01T00:00" }}
						name="start_date"
					/>
				</section>
				<section>
					<span>datum konce</span>
					<Input
						attr={{ type: "datetime-local", min: "2018-01-01T00:00" }}
						name="end_date"
					/>
				</section>
			</div>
			<section>
				<span>místo konání</span>
				<Input name="place" />
			</section>
			<section>
				<span>zpráva</span>
				<Input
					type="textarea"
					name="content"
				/>
			</section>
		</Form>
	);

	if (pure) return renderForm();
	return <div className="administration-container">{renderForm()}</div>;
}

export default NewAkce;
