import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loader from "../Loader";
import Gallery from "../Gallery";

import { fetchData } from "./../../redux";

import "./style.sass";

function Banner({ images = {} as any, fetchData = undefined as any }) {
  // if data were not loaded in App, load them separately
  useEffect(() => {
    if (!Object.keys(images).length) fetchData("image");
  }, [fetchData, images]);

  return (
    <Loader loaded={images?.banner?.length} id="banner" placeholder={false}>
      <Gallery images={images?.banner} open={false} />
    </Loader>
  );
}

const mapStateToProps = (state: any) => {
  return {
    images: state.data.image,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (target = "" as string) => dispatch(fetchData(target)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
