import { combineReducers } from "redux";

import dataReducer from "./data/dataReducer";
import pageReducer from "./page/pageReducer";
import administrationReducer from "./administration/administrationReducer";

const rootReducer = combineReducers({
  data: dataReducer,
  page: pageReducer,
  administration: administrationReducer,
});

export default rootReducer;
