import { useEffect, useState } from "react";

import "./style.sass";

function New({ url = `${process.env.REACT_APP_HOST}/api/post/files.php` as string, params = {} as any }) {
	const [urlParams, setUrlParams] = useState("");
	useEffect(() => {
		const urlParams = new URLSearchParams();
		Object.entries(params).map(([key, value]) => value && urlParams.append(key, value as string));
		setUrlParams(urlParams.toString());
	}, [params, urlParams, setUrlParams]);

	return (
		<div className="administration-data-new">
			<form
				action={`${url}?${urlParams || ""}`}
				method="post"
				encType="multipart/form-data">
				<input
					type="file"
					name="files[]"
					multiple
				/>
				<input
					type="submit"
					className="icon"
					value=" "
				/>
			</form>
		</div>
	);
}

export default New;
