import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import {
  watcherSagaData,
  watcherSagaPage,
  watcherSagaAdministration,
} from "../sagas/rootSaga";

import rootReducer from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();

const middleware = [thunk, sagaMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleware.run(watcherSagaData);
sagaMiddleware.run(watcherSagaPage);
sagaMiddleware.run(watcherSagaAdministration);

export default store;
