import { PAGE_FETCH_ALL, PAGE_FETCH, PAGE_SET } from "./pageTypes";

export const fetchPageAll = () => {
  return {
    type: PAGE_FETCH_ALL,
  };
};

export const fetchPage = (target = "" as string) => {
  return {
    type: PAGE_FETCH,
    target,
  };
};

export const setPage = (data: any) => {
  return {
    type: PAGE_SET,
    data,
  };
};
