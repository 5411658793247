import { call, put, delay } from "redux-saga/effects";

import { requestFetchPageAll, requestFetchPage } from "../requests/page";

import { setPage } from "./../../redux";

export function* handleFetchPageAll(): any {
  try {
    const { data } = yield call(requestFetchPageAll);
    yield put(setPage(data));
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchPage(args = undefined as any): any {
  const { target } = args;
  try {
    // waits if FETCH_ALL is not called
    yield delay(10);

    const response = yield call(requestFetchPage, target);

    yield put(setPage({ [target]: response.data }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleResetPage(args = undefined as any): any {
  const { target } = args;
  try {
    const response = yield call(requestFetchPage, target);

    yield put(setPage({ [target]: response.data }));
  } catch (error) {
    console.log(error);
  }
}
