import React from "react";

import Form from "../../../../components/Form";
import Input from "../../../../components/Input";

import "./../../style.sass";

function NewAktuality({ pure = false as boolean }) {
  const renderForm = () => (
    <Form
      actions={["save"]}
      target="news"
      defaultValues={{ title: "", content: "" }}
    >
      <div className="form-title">
        <section>
          <span>nadpis</span>
          <Input name="title" />
        </section>
        <section className="administration-aktuality-checkBox">
          <span>upevnit</span>
          <Input attr={{ type: "checkbox" }} name="pin" />
        </section>
        <section>
          <span>datum zveřejnění</span>
          <Input attr={{ type: "date", min: "2018-01-01" }} name="date" />
        </section>
      </div>
      <section>
        <span>zpráva</span>
        <Input type="textarea" name="content" />
      </section>
    </Form>
  );

  if (pure) return renderForm();
  return <div className="administration-container">{renderForm()}</div>;
}

export default NewAktuality;
