import React from "react";

import Form from "../../../../components/Form";
import Input from "../../../../components/Input";

function NewSquad() {
	return (
		<div className="administration-container">
			<Form
				actions={["save"]}
				target="squad"
				defaultValues={{
					name: "",
					content: "",
					time: "",
					contact: "",
				}}>
				<div className="form-title">
					<section>
						<span>název</span>
						<Input name="name" />
					</section>
				</div>
				<section>
					<span>informace</span>
					<Input
						type="textarea"
						name="content"
					/>
				</section>
				<div className="administration-oddily-timeAndContact">
					<section>
						<span>termín</span>
						<Input
							type="textarea"
							name="time"
						/>
					</section>
					<section>
						<span>kontakt</span>
						<Input
							type="textarea"
							name="contact"
						/>
					</section>
				</div>
			</Form>
		</div>
	);
}

export default NewSquad;
