import React from "react";

import Files from "../../components/Files";

export default function ZpravyZJednoty() {
	return (
		<main>
			<h1>Zprávy z jednoty</h1>
			<span>soubory nejdříve nahrajte, pak upravte jejich název nebo datum zveřejnění</span>
			<Files
				application="report"
				referer="zpravy-z-jednoty"
			/>
		</main>
	);
}
