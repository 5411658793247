import React from "react";

import Edit from "./components/Edit";
import New from "./components/New";

export default function Files({
  target = "document" as string,
  application = "document" as string,
  id_application = 0 as number,
  referer = "" as string,
}) {
  return (
    <>
      <h3>nový {target === "document" ? "dokument" : "obrázek"}</h3>
      <New params={{ ...{ target, application, id_application, referer } }} />
      <Edit {...{ target, application, id_application }} />
    </>
  );
}
