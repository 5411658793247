import { ADMINISTRATION_LOGIN, ADMINISTRATION_OFFSET } from "./administrationTypes";

const initialState = {
	login: false,
	offset: 0,
};

function administrationReducer(state = initialState, action: any) {
	switch (action.type) {
		case ADMINISTRATION_LOGIN:
			return { ...state, login: action.login };
		case ADMINISTRATION_OFFSET:
			return { ...state, offset: action.offset };
		default:
			return state;
	}
}

export default administrationReducer;
