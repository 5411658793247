import { useEffect } from "react";
import { connect } from "react-redux";

import DocumentsList from "../../components/DocumentsList";
import Sections from "../../components/Sections";

import { fetchData } from "./../../redux";

function Dokumenty({ documents = {} as any, fetchData = undefined as any }) {
	// if squads were not loaded in App
	useEffect(() => {
		if (!Object.keys(documents).length) fetchData("document");
	}, [fetchData, documents]);

	return (
		<div id="documents">
			<h1>
				Dokumenty
				<Sections
					sections={[
						{ id: "ukrajina", title: "Ukrajina" },
						{ id: "cvicebni-provoz", title: "Cvičební provoz" },
						{ id: "zpravy-z-jednoty", title: "Zprávy z jednoty" },
					]}
				/>
			</h1>
			<section>
				<DocumentsList
					documents={documents?.document}
					showDate
					hideEmpty
				/>
			</section>
			<section id="ukrajina">
				<h2>Ukrajina</h2>
				<DocumentsList
					documents={documents?.ukraine}
					hideEmpty
				/>
			</section>
			<section id="cvicebni-provoz">
				<h2>Cvičební provoz</h2>
				<DocumentsList
					documents={documents?.operation}
					showDate
					hideEmpty
				/>
			</section>
			<section id="zpravy-z-jednoty">
				<h2>Zprávy z jednoty</h2>
				<DocumentsList
					documents={documents?.report}
					showDate
					hideEmpty
				/>
			</section>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		documents: state.data.document,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dokumenty);
