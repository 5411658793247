import { take, fork, takeEvery, takeLatest } from "redux-saga/effects";

import { DATA_FETCH_ALL, DATA_FETCH } from "../redux/data/dataTypes";
import { handleFetchData, handleFetchDataAll } from "./handlers/data";

import { PAGE_FETCH_ALL, PAGE_FETCH } from "../redux/page/pageTypes";
import { handleFetchPage, handleFetchPageAll } from "./handlers/page";

import {
    ADMINISTRATION_STATUS,
    ADMINISTRATION_SAVE,
    ADMINISTRATION_DELETE,
    ADMINISTRATION_MOVE,
    ADMINISTRATION_UPDATE,
} from "../redux/administration/administrationTypes";
import {
    handleRequestStatus,
    handleRequestSave,
    handleRequestDelete,
    handleRequestMove,
    handleRequestUpdate,
} from "./handlers/administration";

export function* watcherSagaData(): any {
    let fetch = yield takeEvery(DATA_FETCH, handleFetchData);

    while (yield take(DATA_FETCH_ALL)) {
        // resets all pure DATA_FETCH
        fetch.cancel();
        fetch = yield takeEvery(DATA_FETCH, handleFetchData);

        yield fork(handleFetchDataAll);
    }
}

export function* watcherSagaPage(): any {
    let fetch = yield takeEvery(PAGE_FETCH, handleFetchPage);

    while (yield take(PAGE_FETCH_ALL)) {
        // resets all pure PAGE_FETCH
        fetch.cancel();
        fetch = yield takeEvery(PAGE_FETCH, handleFetchPage);

        yield fork(handleFetchPageAll);
    }
}

export function* watcherSagaAdministration(): any {
    yield takeEvery(ADMINISTRATION_SAVE, handleRequestSave);
    yield takeEvery(ADMINISTRATION_DELETE, handleRequestDelete);
    yield takeEvery(ADMINISTRATION_MOVE, handleRequestMove);
    yield takeEvery(ADMINISTRATION_UPDATE, handleRequestUpdate);
    yield takeLatest(ADMINISTRATION_STATUS, handleRequestStatus);
}
