import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/cs";
import cx from "classnames";

import "./style.sass";

import { months } from "../../../../const";

function Events({
	events = [] as any[],
	active = "" as string,
	setActive = undefined as any,
	month = undefined as any,
	setMonth = undefined as any,
	year = undefined as any,
	setYear = undefined as any,
	checkInterval = undefined as any,
}) {
	// creates first day to render month
	const [firstDate, setFirstDate] = useState<string>("");
	const [lastDate, setLastDate] = useState<string>("");
	const [strtDate, setStrtDate] = useState<any>(null);

	const [days, setDays] = useState<any>([]);

	useEffect(() => {
		// useEffect saves data after is completed, local variable is needed
		const _firstDate = `${year}-${("0" + (month + 1)).slice(-2)}-01`;

		// when month or year is changed, it is important to store those values in state, so entire component gets rerendered
		setFirstDate(_firstDate);
		setLastDate(moment(_firstDate).endOf("month").format("YYYY-MM-DD"));
		setStrtDate(moment(_firstDate).subtract(moment(_firstDate).day() - 1, "day"));
	}, [year, month]);

	useEffect(() => {
		// adds all days from that month to the array
		let _days = [] as any[];
		const months = [month < 1 ? 11 : month - 1, month];
		while (months.includes(strtDate?.month()))
			for (let i = 0; i < 7; ++i, strtDate.add(1, "days")) _days = [..._days, moment(strtDate)];
		setDays(_days);
	}, [strtDate, month]);

	return (
		<div className="events-calendar">
			<button
				className="icon events-button-left"
				onClick={() => {
					setMonth(
						month > 0
							? month - 1
							: () => {
									setYear(year - 1);
									return 11;
							  }
					);
					setActive("");
				}}></button>
			<dl>
				<dt>
					{months[month]}
					<span>{year}</span>
				</dt>
				<dd className="events-daysInWeek">
					{[1, 2, 3, 4, 5, 6, 7].map((day = 0 as number, key) => (
						<div key={key}>{moment().isoWeekday(day).format("dd")}</div>
					))}
				</dd>
				<dd>
					{days.map((day = undefined as any, key = 0 as number) => (
						<div
							key={key}
							className={cx({
								today: moment().isSame(day, "day"),
								off: day.isBefore(moment(firstDate)) || day.isAfter(moment(lastDate)),
								active: moment(active).isSame(day, "day"),
								in: events?.filter((event = undefined as any) => checkInterval(event?.start_date, event?.end_date, day))
									.length,
							})}
							onClick={() => {
								if (moment(active).isSame(day, "day")) setActive("");
								else setActive(day.format("YYYY-MM-DD"));
							}}>
							{day.format("D")}
						</div>
					))}
				</dd>
			</dl>
			<button
				className="icon events-button-right"
				onClick={() => {
					setMonth(
						month < 11
							? month + 1
							: () => {
									setYear(year + 1);
									return 0;
							  }
					);
					setActive("");
				}}></button>
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		events: state.data.event,
	};
};

export default connect(mapStateToProps, null)(Events);
