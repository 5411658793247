import { useParams } from "react-router-dom";
import cx from "classnames";

import { Link } from "react-router-dom";

import { pages } from "../../../../const";

import "./style.sass";

function Nav() {
	let { page } = useParams();

	return (
		<section id="administration-nav">
			<div id="administration-nav-holder">
				{pages
					.filter(page => page.list === "admin")
					.map((adminPage, index) => (
						<Link
							key={index}
							className={cx({ active: `/${page}` === adminPage.link })}
							to={adminPage.link}>
							{adminPage.title.toLowerCase()}
						</Link>
					))}
				<a href={`${process.env.REACT_APP_HOST}/api/login.php`}>odhlásit</a>
			</div>
		</section>
	);
}

export default Nav;
