import { DATA_FETCH_ALL, DATA_FETCH, DATA_SET, DATA_UPDATE, DATA_DELETE, DATA_MOVE, DATA_SAVE } from "./dataTypes";

export const fetchDataAll = () => {
	return {
		type: DATA_FETCH_ALL,
	};
};

export const fetchData = (target = "" as string) => {
	return {
		type: DATA_FETCH,
		target,
	};
};

export const setData = (data: any) => {
	return {
		type: DATA_SET,
		data,
	};
};

export const updateData = (target = "" as string, value = {} as any) => {
	return {
		type: DATA_UPDATE,
		target,
		value,
	};
};

export const deleteData = (target = "" as string, data = {} as any) => {
	return {
		type: DATA_DELETE,
		target,
		data,
	};
};

export const saveData = (target = "" as string, value = {} as any) => {
	return {
		type: DATA_SAVE,
		target,
		value,
	};
};

export const moveData = (target = "" as string, data = {} as any, action = "moveUp" as string) => {
	return {
		type: DATA_MOVE,
		target,
		data,
		action,
	};
};
