import { useEffect, useState, useRef } from "react";
import cx from "classnames";

import Pop from "./components/Pop";
import Buttons from "./components/Buttons";

import "./style.sass";

function Gallery({ images = [] as any[], className = "" as string, open = true as boolean }) {
	// index of current active image
	const [active, setActive] = useState(0);

	// pop flag
	const [pop, setPop] = useState(false);

	// to stop moving while hovering
	const [pointer, setPointer] = useState(false);

	// ref to the container
	const currentRef = useRef<null | any>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!pop && !pointer) {
				setActive(active >= images.length - 1 ? 0 : active + 1);
			}
		}, 7500);

		return () => clearInterval(interval);
	});

	// for empty album
	// simplier render than condition in component
	if (!images.length) return null;

	return (
		<div
			className={cx("gallery", className)}
			onMouseEnter={() => {
				setPointer(true);
			}}
			onMouseLeave={() => {
				setPointer(false);
			}}>
			<Buttons
				imagesLen={images.length}
				active={active}
				setActive={setActive}
			/>
			<div className="gallery-images">
				{images.map((image, index) => (
					<img
						ref={(index === active && currentRef) || null}
						key={index}
						className={cx({ "gallery-link": image.url, active: index === active })}
						src={`${process.env.REACT_APP_HOST}/data/image/${image.source}`}
						alt={image?.alt || null}
						onClick={() => {
							if (image.url) window.open(image.url, "_blank");
							else {
								setActive(index);
								setPop(true);
							}
						}}
					/>
				))}
			</div>
			{open && pop && (
				<Pop
					images={images}
					active={active}
					setActive={setActive}
					setPop={setPop}
				/>
			)}
		</div>
	);
}

export default Gallery;
