const initialState = {
  aboutus: "",
  history: "",
  contact: "",
};

function pageReducer(state = initialState, action: any) {
  const {
    aboutus = state.aboutus,
    history = state.history,
    contact = state.contact,
  } = action?.data || state;

  switch (action.type) {
    default:
      return { ...state, aboutus: aboutus, history: history, contact: contact };
  }
}

export default pageReducer;
