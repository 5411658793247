import { call, put, delay } from "redux-saga/effects";

import {
  requestFetchDataAll,
  requestFetchData,
  requestFetchDataSpec,
} from "../requests/data";

import { setData } from "./../../redux";

// specific endpoint, that has to be loaded from other url
const specific = ["schedule"];
const content = ["document", "image"];

export function* handleFetchDataAll(): any {
  try {
    const { data } = yield call(requestFetchDataAll);
    yield put(setData(data));
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchData(args = undefined as any): any {
  const { target } = args;
  try {
    // waits if FETCH_ALL is not called
    yield delay(10);

    let response: any;
    if (specific.includes(target))
      response = yield call(requestFetchDataSpec, target);
    else
      response = yield call(
        requestFetchData,
        target,
        content.includes(target) ? "content" : "list"
      );

    yield put(setData({ [target]: response.data }));
  } catch (error) {
    console.log(error);
  }
}
