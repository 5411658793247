import React from "react";
import { connect } from "react-redux";

import { updateAdministration } from "../.././.././../redux";

function Submit({
  target = "" as string,
  refresh = "data" as string,
  data = {} as object,
  updateAdministration = undefined as any,
}) {
  return (
    <button
      className="administration-submit"
      onClick={() => updateAdministration(target, refresh, data)}
    >
      uložit
    </button>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAdministration: (
      target = "" as string,
      refresh = "data" as string,
      data = {} as object
    ) => dispatch(updateAdministration(target, refresh, data)),
  };
};

export default connect(null, mapDispatchToProps)(Submit);
